import { all, put, takeEvery } from "redux-saga/effects";
import { getLocationsByLocationIds } from "../../Services/database";
import { isValidObject } from "../../Services/validators";
import {
  calculateDistance,
  isNearSelectedLocation,
  liveLocationUpdateTime,
  movePerSecond,
} from "../../Utils/constants";
import { setErrorStatus } from "../status/action";
import store from "../store";

export const actionTypes = {
  GET_LOCATIONS: "GET_LOCATIONS",
  SET_USER_LOCATION: "SET_USER_LOCATION",
  ADD_USER_DISTANCE: "ADD_USER_DISTANCE",
  ADD_SELECTED_LOCATION: "ADD_SELECTED_LOCATION",
  CHECK_FOR_VALID_LOCATION: "CHECK_FOR_VALID_LOCATION",
};

function* setUserLocationWorker(action) {
  try {
    const currentLocation = action.payload.currentLocation;
    const locations = store.getState().locations;
    if (
      locations.currentLocation === null ||
      locations.currentLocation?.latitude === null ||
      locations.currentLocation?.longitude === null
    ) {
      yield put({
        type: "SET_USER_CURRENT_LOCATION",
        payload: {
          data: {
            ...action.payload.currentLocation,
            timestamp: +new Date(),
          },
        },
      });
    }

    if (
      locations.currentLocation?.latitude &&
      locations.currentLocation?.longitude
    ) {
      const distance = calculateDistance(
        locations.currentLocation,
        currentLocation
      );
      const lastTimeStamp = locations.currentLocation.timestamp;
      const currentTime = +new Date();
      const timeDifference = (currentTime - lastTimeStamp) / 1000;

      if (
        movePerSecond * timeDifference >= distance ||
        timeDifference >= liveLocationUpdateTime
      ) {
        yield put({
          type: "SET_USER_CURRENT_LOCATION",
          payload: {
            data: {
              ...action.payload.currentLocation,
              timestamp: currentTime,
            },
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* addUserDistanceWorker() {
  try {
    const locations = store.getState().locations;
    const geoLoc = locations?.data?.[locations?.selectedLocation]?._geoloc;

    if (isValidObject(locations?.currentLocation)) {
      yield put({
        type: "SET_USER_DISTANCE",
        payload: {
          distance: calculateDistance(
            {
              latitude: geoLoc?.lat,
              longitude: geoLoc?.lng,
            },
            locations?.currentLocation
          ),
        },
      });
    } else {
      yield put({
        type: "SET_USER_DISTANCE",
        payload: {
          distance: null,
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLocationsWorker(action) {
  try {
    setLocationsLoading(true);
    const response = yield getLocationsByLocationIds(
      action.payload.locationIds
    );
    yield put({
      type: "SET_LOCATIONS_DATA",
      payload: { data: response },
    });
    setLocationsLoading(false);
  } catch (error) {
    setLocationsLoading(false);
    setErrorStatus(error);
  }
}

function* checkForValidLocationsWorker() {
  try {
    setLocationsLoading(true);
    const locations = store.getState().locations;

    if (isValidObject(locations.data)) {
      const response = isNearSelectedLocation(
        locations.currentLocation,
        locations.data[locations.selectedLocation]
      );
      yield put({
        type: "SET_NEAR_LOCATION",
        payload: { data: response },
      });
    }
    setLocationsLoading(false);
  } catch (error) {
    setLocationsLoading(false);
    setErrorStatus(error);
  }
}

function* addSelectedLocationWorker(action) {
  try {
    yield setLocationsLoading(true);
    yield put({
      type: "SET_SELECTED_LOCATION",
      payload: { data: action.payload.data },
    });
    yield setLocationsLoading(false);
  } catch (error) {
    yield setLocationsLoading(false);
    setErrorStatus(error);
  }
}

export default function* locationsWatcher() {
  yield all([
    takeEvery("SET_USER_LOCATION", setUserLocationWorker),
    takeEvery("ADD_USER_DISTANCE", addUserDistanceWorker),
    takeEvery("GET_LOCATIONS", getLocationsWorker),
    takeEvery("CHECK_FOR_VALID_LOCATION", checkForValidLocationsWorker),
    takeEvery("ADD_SELECTED_LOCATION", addSelectedLocationWorker),
  ]);
}

function* setLocationsLoading(bool) {
  yield put({
    type: "SET_LOCATIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
