import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  ArrowIcon,
  CloseIcon,
  FallbackClipArt,
  SearchIcon,
} from "../../Assets/assets";
import { search } from "fast-fuzzy";
import Header from "../../Components/Header/Header";
import { isValidArray, isValidObject } from "../../Services/validators";
import { setSelectedLocation } from "../../Redux/locations/action";

function LocationSearch(props) {
  const [searchKey, setSearchKey] = useState("");
  const results = useMemo(() => {
    if (isValidObject(props.locations.data)) {
      const locationData = Object.values(props.locations.data);
      if (!searchKey) {
        return locationData;
      }
      return search(searchKey, locationData, {
        keySelector: (item) => `${item.location} ${item.PCTNo}`,
        threshold: 0.7,
      });
    }
  }, [searchKey, props.locations.data]);
  return (
    <div className="inherit-parent-height inherit-parent-width overflow-hidden">
      <Header
        title={
          window.location.pathname.includes("faceIdentification")
            ? "Face Identification"
            : "Procedure"
        }
        backOnClick={() => {
          props.navigate("/");
        }}
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />

      <div className="display-flex padding-top-large padding-left-large padding-right-large flex-direction-column">
        <div className="font-family-RHD-medium ">Select location</div>
        <div className=" font-size-small padding-bottom-default">
          {window.location.pathname.includes("faceIdentification")
            ? "Select your current location to proceed"
            : "Select the locations to view the upcoming procedures"}
        </div>
      </div>
      <>
        <div className="display-flex flex-justify-content-center flex-align-items-center border-bottom-1px margin-right-large margin-left-large margin-top-default margin-bottom-large">
          <input
            style={{
              width: "100%",
            }}
            className={`inherit-parent-height border-none font-family-RHD-regular font-size-large padding-top-default padding-top-default padding-bottom-default padding-right-default inherit-parent-width`}
            value={searchKey}
            autoFocus={true}
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
            placeholder="Search"
            title="Search bar"
            data-cy="search-input-field"
          />
          <button
            className="padding-right-default background-color-transparent"
            type={"button"}
            data-cy={
              searchKey?.length > 0 ? "search-clear-button" : "search-button"
            }
            onClick={() => {
              if (searchKey?.length > 0) {
                setSearchKey("");
              }
            }}
          >
            {searchKey?.length < 1 ? (
              <SearchIcon />
            ) : (
              <CloseIcon width={20} height={20} />
            )}
          </button>
        </div>

        <div className=" inherit-parent-width remaining-body-height_-229px flex-justify-content-center ">
          <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar flex-justify-content-center">
            {!props.locations.loading &&
              isValidObject(props.locations.data) &&
              isValidArray(results) && (
                <div className="inherit-parent-width inherit-parent-height">
                  <div
                    className="padding-left-large padding-right-large padding-bottom-large inherit-parent-width inherit-parent-height"
                    data-cy="locations-list"
                  >
                    {results.map((data, index) => (
                      <div className="inherit-parent-width" key={index}>
                        <Card
                          onClick={() => {
                            props.setSelectedLocation(data.documentId);
                            if (
                              window.location.pathname.includes(
                                "faceIdentification"
                              )
                            ) {
                              props.navigate("/faceIdentification");
                            } else {
                              props.navigate(`/${data.documentId}/procedures`);
                            }
                          }}
                          index={index}
                          data={data}
                          locations={props.locations?.data}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {!props.locations.loading && !isValidArray(results) && (
              <NoLocationsFallback />
            )}

            {props.locations.loading && <Suspense />}
          </div>
        </div>
      </>
    </div>
  );
}

const Card = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="display-flex inherit-parent-width padding-bottom-large"
      data-cy={`location-card-${props.data.documentId}`}
    >
      <div className="inherit-parent-width display-flex flex-justify-content-space-between">
        <div className=" parent-width_-68px">
          <>
            <div className=" font-size-medium font-family-RHD-medium padding-bottom-small inherit-parent-width text-overflow-ellipsis">
              {props.data.location}
            </div>
            <div className="font-size-small">
              {`${props.data?.PCTNo?.toString().padStart(2, "0")}
                - ${props.data.location}`}
            </div>
          </>
        </div>
        <div className=" display-flex flex-align-items-center ">
          <div className=" display-flex flex-align-items-center ">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const NoLocationsFallback = () => {
  return (
    <div
      className="inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-locations-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        Searched Locations are not Available
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after sometime.
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <div className=" inherit-parent-width padding-top-large">
      {[...Array(7)].map((_data, index) => (
        <div className=" padding-default inherit-parent-width" key={index}>
          <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations,
  };
};
const mapDispatchToProps = function () {
  return {
    setSelectedLocation: (locationId) => setSelectedLocation(locationId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearch);
