import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import { rippleEffect } from "../../Utils/constants";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import {
  checkFaceComparison,
  clearFaceComparison,
} from "../../Redux/faceComparison/action";
import FaceDetection from "../../Components/FaceDetection/FaceDetection";
import { CameraFlipIcon, NotInLocationPicture } from "../../Assets/assets";
import { Route, Routes } from "react-router-dom";
import { isValidArray, isValidObject } from "../../Services/validators";
import { checkIfUserIsInTheSelectedLocation } from "../../Redux/locations/action";

function FaceIdentification(props) {
  const [capturedImage, setCapturedImage] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isFaceDetected, setIsFaceDetected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.checkIfUserIsInTheSelectedLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (capturedImage && !window.location.pathname.includes("/success")) {
      props.navigate("/faceIdentification/capturedImage");
    }

    if (!capturedImage && window.location.pathname.includes("/success")) {
      props.navigate("/faceIdentification");
    }

    if (capturedImage && isValidArray(props.faceComparison.data)) {
      props.navigate("/faceIdentification/success");
    }
    // eslint-disable-next-line
  }, [capturedImage, props.faceComparison.data]);

  useEffect(() => {
    props.clearFaceComparison();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      console.log("Navigation occurred!", event);
      const state = event.state;

      if (state) {
        console.log("New history state:", state);
        setCapturedImage(null);
      } else {
        console.log("No state associated with this history entry.");
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="inherit-parent-height inherit-parent-width overflow-hidden">
      <Header
        title="Face Identification"
        profileOnClick={() => {
          props.navigate("/profile");
          setCapturedImage(null);
          props.clearFaceComparison();
        }}
        backOnClick={() => {
          const locationPathName = window.location.pathname;
          if (locationPathName === "/faceIdentification") {
            props.navigate("/faceIdentification/locationSearch");
          } else if (
            locationPathName.includes("/faceIdentification/capturedImage")
          ) {
            props.navigate("/faceIdentification");
          } else if (locationPathName.includes("success")) {
            props.navigate("/");
          } else {
            props.navigate("/");
          }
          setCapturedImage(null);
          props.clearFaceComparison();
        }}
      />
      <div className="remaining-body-height">
        {props.locations.loading && (
          <div className="inherit-parent-width inherit-parent-height flex-justify-content-center flex-align-items-center padding-larger">
            <div className="display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
              <div className="primary-loading-animation" />
              <div className="text-align-center padding-top-larger">
                Validating your current location to confirm compliance with the
                required criteria.
              </div>
            </div>
          </div>
        )}
        {!isValidObject(props.locations.nearLocation) &&
          !props.locations.loading && (
            <div
              className="remaining-procedure-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
              data-cy="notInLocation-fallBack"
            >
              <NotInLocationPicture />
              <div className="padding-top-larger font-family-RHD-medium font-size-default">
                Not in location !
              </div>
              <div className="padding-top-medium text-align-center font-size-medium">
                Reach the assigned location to continue.
              </div>
            </div>
          )}

        {isValidObject(props.locations.nearLocation) &&
          !props.locations.loading &&
          !capturedImage && (
            <CaptureImage
              isCameraOn={isCameraOn}
              setIsCameraOn={setIsCameraOn}
              setCapturedImage={setCapturedImage}
              setFacingMode={setFacingMode}
              facingMode={facingMode}
            />
          )}

        <Routes>
          <Route
            path="/capturedImage"
            element={
              <CapturedImage
                facingMode={facingMode}
                capturedImage={capturedImage}
                setIsFaceDetected={setIsFaceDetected}
                setCapturedImage={setCapturedImage}
                isFaceDetected={isFaceDetected}
                loading={loading}
                checkLoading={props.faceComparison.loading}
                setLoading={setLoading}
                checkOnClick={() => {
                  props.checkFaceComparison(capturedImage);
                }}
              />
            }
          />
          <Route
            path="/Success"
            element={
              <ComparisonSuccess
                continueOnClick={() => {
                  props.navigate("/");
                  props.clearFaceComparison();
                  setCapturedImage(null);
                }}
                capturedImage={capturedImage}
                faceComparisonData={props.faceComparison.data?.[0]}
                buttonText={"Okay"}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    faceComparison: state.faceComparison,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {
    checkFaceComparison: (file) => checkFaceComparison(file),
    clearFaceComparison: () => clearFaceComparison(),
    checkIfUserIsInTheSelectedLocation: () =>
      checkIfUserIsInTheSelectedLocation(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceIdentification);

const CaptureImage = (props) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = async () => {
    const facingMode = props.facingMode;
    try {
      const constraints = {
        video: { facingMode },
        audio: false,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        props.setIsCameraOn(true);
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert("Could not access the camera. Please allow camera permissions.");
    }
  };

  const captureImage = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = canvas.toDataURL("image/png");
      props.setCapturedImage(imageData);

      const tracks = video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());

      props.setIsCameraOn(false);
    } else {
      console.error("Camera is not initialized properly.");
    }
  };

  useEffect(() => {
    startCamera();
    const currentVideoRef = videoRef.current;

    return () => {
      if (currentVideoRef?.srcObject) {
        const tracks = currentVideoRef.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line
  }, [props.facingMode]);

  const switchCamera = () => {
    props.setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };
  return (
    <div className="inherit-parent-height inherit-parent-width position-relative">
      <>
        <video
          ref={videoRef}
          playsInline
          autoPlay
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            ...(props.facingMode === "user"
              ? {
                  transform: "scaleX(-1)",
                  WebkitTransform: "scaleX(-1)",
                }
              : {}),
          }}
        />
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <div
          style={{
            bottom: "40px",
          }}
          className="position-absolute display-flex flex-justify-content-space-between flex-align-items-center inherit-parent-width"
        >
          <div
            style={{
              marginLeft: "32px",
              padding: "16px",
            }}
          />
          <div
            className=""
            onClick={(event) => {
              rippleEffect(event);
              captureImage();
            }}
          >
            <CaptureIcon />
          </div>
          <div
            style={{
              marginRight: "32px",
            }}
            onClick={(event) => {
              rippleEffect(event);
              switchCamera();
            }}
          >
            <CameraFlipIcon />
          </div>
        </div>
      </>
    </div>
  );
};

const CapturedImage = (props) => {
  return (
    <>
      <div className="display-flex padding-top-large padding-left-large padding-right-large flex-direction-column">
        <div className="font-family-RHD-medium">Captured Image</div>
        <div className=" font-size-small">Validate the captured image</div>
      </div>
      <div className="remaining-body-height flex-justify-content-space-between flex-direction-column">
        <FaceDetection
          facingMode={props.facingMode}
          capturedImage={props.capturedImage}
          setIsFaceDetected={props.setIsFaceDetected}
          setLoading={props.setLoading}
        />
        <div className="padding-left-large padding-right-large">
          {props.isFaceDetected !== null && props.loading === false && (
            <div className="font-size-medium text-align-center padding-bottom-large">
              {props.isFaceDetected
                ? "Face detected "
                : "No face detected. Try with a clear image"}
              &nbsp;
              <span
                onClick={() => {
                  props.setCapturedImage(null);
                }}
                className="font-color-primary cursor-pointer"
              >
                Retake
              </span>
            </div>
          )}
          <div>
            <Button
              loading={props.checkLoading}
              type="button"
              className="margin-bottom-large"
              text="Confirm"
              disabled={props.isFaceDetected && !props.loading ? false : true}
              onClick={props.checkOnClick}
              boxShadow={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ComparisonSuccess = (props) => {
  return (
    <div className="inherit-parent-height inherit-parent-width">
      <div
        style={{
          paddingTop: "64px",
        }}
        className="display-flex padding-left-large padding-right-large flex-direction-column"
      >
        <div className="font-family-RHD-medium text-align-center">
          FACE MATCHED SUCCESSFULLY
        </div>
        <div className="font-size-small text-align-center">
          Attendance has been granted successfully for
        </div>
        <div className="font-size-small font-family-RHD-medium text-align-center">
          {props.faceComparisonData?.matched?.employee.name}
        </div>
      </div>
      <div
        style={{
          height: "calc(100% - 104px)",
        }}
        className="inherit-parent-width display-flex flex-direction-column padding-large flex-justify-content-space-between"
      >
        <div
          style={{
            paddingTop: "64px",
          }}
        >
          <div className="font-size-larger font-family-RHD-medium flex-justify-content-center flex-align-items-center flex-direction-column">
            <div className="wrapper">
              <svg className="checkmark" viewBox="0 0 52 52">
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>

            <div
              style={{ fontSize: "20px" }}
              className="text-align-center padding-top-medium"
            >
              {props.text}
            </div>
          </div>

          <div
            style={{
              paddingTop: "64px",
            }}
            className="display-flex flex-justify-content-space-between"
          >
            <div>
              <div className="font-size-small padding-bottom-default">
                CAPTURED IMAGE
              </div>
              <div
                style={{
                  width: "156px",
                  height: "222px",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                  }}
                  className="inherit-parent-height inherit-parent-width"
                  src={props.capturedImage}
                  alt="capturedImage"
                />
              </div>
            </div>
            <div>
              <div className="font-size-small padding-bottom-default">
                MATCHING IMAGE
              </div>
              <div
                style={{
                  width: "156px",
                  height: "222px",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                  }}
                  className="inherit-parent-height inherit-parent-width"
                  src={`data:image/png;base64,${props.faceComparisonData?.matched?.photo}`}
                  alt="matchingImage"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inherit-parent-width flex-justify-content-center">
          <Button
            data-cy={"continue-to-home-button"}
            type="button"
            text={props.buttonText}
            boxShadow={false}
            onClick={() => {
              props.continueOnClick();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CaptureIcon = () => {
  return (
    <>
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
        <circle cx="32" cy="32" r="31" stroke="#F2F2F2" strokeWidth="2" />
        <circle cx="32" cy="32" r="24" fill="#F2F2F2" />
      </svg>
    </>
  );
};
