import { useState, useEffect } from "react";

const useLiveLocation = (isAuth, isLocationPermissionGranted) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    if (!isLocationPermissionGranted) {
      return;
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const successCallback = (position) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const errorCallback = (error) => {
      console.error("Error retrieving geolocation:", error);
    };

    const watchId = navigator.geolocation.watchPosition(
      successCallback,
      errorCallback,
      options
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };

    // eslint-disable-next-line
  }, [isAuth]);

  return location;
};

export default useLiveLocation;
